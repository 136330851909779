import { AnchorHTMLAttributes } from 'react';
import { BrushVariants } from './BrushStrokeWord';
import { CircleVariants } from './CircledWord';
import Typography, { TypographyVariants } from './Typography';

export type DecorationVariants = TypographyVariants | BrushVariants | CircleVariants;

type Props = AnchorHTMLAttributes<HTMLElement> & {
  variant?: TypographyVariants;
  decoration?: DecorationVariants;
  tag?: string;
  value?: string;
  svgName?: string;
  underlineColor?: string;
};

/**
 * @deprecated does not exist in Kurppa Brand
 */
const Scribble = ({ decoration, ...rest }: Props) => {
  if (!decoration || rest.variant) {
    return <Typography useBuiltInMargins {...rest} />;
  }

  if (rest.children) {
    return <>{rest.children}</>;
  }

  return null;
};

export default Scribble;
