import { createContext } from 'react';

type templateReplacerFunction = (text: string) => string;
type ContextProps = {
  templateReplacer: templateReplacerFunction;
} & Record<string, string | number | templateReplacerFunction>;

export const TemplatingContext = createContext<ContextProps>({
  templateReplacer: (text) => text,
});
