import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';

export interface TypeScribbleFields {
  identifier?: EntryFieldTypes.Symbol;
  text?: EntryFieldTypes.Symbol;
  variant?: EntryFieldTypes.Symbol<
    | 'body'
    | 'bodyBold'
    | 'bodySmall'
    | 'bodySmallBold'
    | 'detail'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'overline'
    | 'overlineBold'
  >;
  decoration?: EntryFieldTypes.Symbol<
    | 'Double02'
    | 'Double04'
    | 'brush'
    | 'circleOld'
    | 'circular'
    | 'circular10'
    | 'circular10Large'
    | 'circular13'
    | 'circular21'
    | 'longBrush'
    | 'longH1'
    | 'multipleUnderlines'
    | 'shortH1'
    | 'underline'
    | 'underline10'
    | 'underline11'
    | 'underline17'
  >;
  color?: EntryFieldTypes.Symbol<
    | 'black'
    | 'blackDisabled'
    | 'blackPrimary'
    | 'blackSecondary'
    | 'green100'
    | 'green200'
    | 'green300'
    | 'green400'
    | 'green500'
    | 'green600'
    | 'grey01'
    | 'grey02'
    | 'grey03'
    | 'grey04'
    | 'orange100'
    | 'orange200'
    | 'orange300'
    | 'orange400'
    | 'orange500'
    | 'orange600'
    | 'purple100'
    | 'purple200'
    | 'purple300'
    | 'purple400'
    | 'purple500'
    | 'purple600'
    | 'red100'
    | 'red200'
    | 'red300'
    | 'red400'
    | 'red500'
    | 'red600'
    | 'white'
    | 'whiteDisabled'
    | 'whitePrimary'
    | 'whiteSecondary'
    | 'yellow100'
    | 'yellow200'
    | 'yellow300'
    | 'yellow400'
    | 'yellow500'
    | 'yellow600'
  >;
  richText?: EntryFieldTypes.RichText;
  variants?: EntryFieldTypes.Array<
    EntryFieldTypes.Symbol<
      | 'Double02'
      | 'Double04'
      | 'body'
      | 'bodyBold'
      | 'brush'
      | 'buttonLarge'
      | 'buttonLargeP'
      | 'buttonSmall'
      | 'callToAction'
      | 'campaignTitle'
      | 'causeDescription'
      | 'circleOld'
      | 'circular10'
      | 'circular10Large'
      | 'circular13'
      | 'h1Hand'
      | 'h2Hand'
      | 'h3Hand'
      | 'h4Hand'
      | 'hand'
      | 'handQuote'
      | 'handSmall'
      | 'handSubtitle'
      | 'handTitle'
      | 'inputField'
      | 'label'
      | 'longBrush'
      | 'longH1'
      | 'multipleUnderlines'
      | 'preamble'
      | 'scribbleDisplay'
      | 'shortH1'
      | 'smallText'
      | 'smallTextBold'
      | 'title'
      | 'titleDisplay'
      | 'titleH1'
      | 'titleH2'
      | 'titleH3'
      | 'titleH4'
      | 'titleH4XL'
      | 'titleH5'
      | 'underline'
      | 'underline10'
      | 'underline11'
      | 'underline17'
    >
  >;
}

export type TypeScribbleSkeleton = EntrySkeletonType<TypeScribbleFields, 'scribble'>;
export type TypeScribble<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeScribbleSkeleton, Modifiers, Locales>;

export function isTypeScribble<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeScribble<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'scribble';
}
