import {
  CSSProperties,
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { microcopySet, useMicrocopy } from '../../../../utils/contentfulMicrocopy';
import { Variant } from '../../types/VariantTypes';
import InputBase from './Base';

function notEmptyValidation(value: string): boolean {
  return !!value && value.length > 0;
}

function passwordValidation(password: string): boolean {
  return !!password && password.length >= 6;
}

function validateEmailValidation(email: string): boolean {
  // \\S matches any non-whitespace character, matches against [string w/o ws, length >= 1]@[string w/o ws, length >= 1].[string w/o ws , length >= 2]
  // Examples which fail:
  // Nothing before or after @: @domain.some, test@
  // Whitespaces: te st@domain.some, test@dom ain.some, test@domain.so me,
  // No @ or dot: testdomain.some, test@domainsome
  // Insufficient length of domain prefix or suffix: test@.some, test@domain.s
  const regex = new RegExp('^\\S+@\\S+\\.\\S{2,}$');
  return !!email && regex.test(email);
}

export const validations = {
  company: [
    {
      validate: notEmptyValidation,
      errorMessageTranslationKey: 'enterCompany',
    },
  ],
  email: [
    {
      validate: notEmptyValidation,
      errorMessageTranslationKey: 'enterEmail',
    },
    {
      validate: validateEmailValidation,
      errorMessageTranslationKey: 'invalidEmail',
    },
  ],
  password: [
    {
      validate: notEmptyValidation,
      errorMessageTranslationKey: 'enterPassword',
    },
    {
      validate: passwordValidation,
      errorMessageTranslationKey: 'passwordLength',
    },
  ],
  givenName: [
    {
      validate: notEmptyValidation,
      errorMessageTranslationKey: 'enterGivenName',
    },
  ],
  familyName: [
    {
      validate: notEmptyValidation,
      errorMessageTranslationKey: 'enterFamilyName',
    },
  ],
};

export function isValidationKey(key?: string): key is keyof typeof validations {
  return !!key && key in validations;
}

export type Validation = {
  validate: (value: string) => boolean;
  errorMessage?: string;
  errorMessageTranslationKey?: string;
};

export const runValidations = (validations: Validation[], value: string) =>
  validations.find(({ validate }: Validation) => !validate(value));

type Props = {
  defaultValue?: string;
  value?: string;
  label?: string;
  liftedLabel?: boolean;
  error?: string | ReactNode;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => void;
  className?: string;
  type?: string;
  validations?: Validation[];
  showSuccess?: boolean;
  successMessage?: string;
  validateWithoutFocus?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLElement>, isValid?: boolean) => void;
  name?: string;
  required?: boolean;
  autoComplete?: string;
  style?: CSSProperties;
  variant?: Variant;
  skipBlurValidation?: boolean;
  setAnyErrors?: (hasErrors: boolean) => void;
} & InputHTMLAttributes<HTMLElement>;

const TextInput = ({
  className,
  value,
  onChange,
  defaultValue,
  label,
  liftedLabel,
  type,
  validations = [],
  successMessage,
  showSuccess,
  validateWithoutFocus,
  multiline,
  disabled,
  autoComplete,
  onBlur,
  name,
  required,
  maxLength,
  autoFocus,
  placeholder,
  style,
  error,
  skipBlurValidation = false,
  setAnyErrors = () => {},
}: Props) => {
  const t = useMicrocopy();
  const [hasFocus, setHasFocus] = useState(!!autoFocus);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [validationSuccessful, setValidationSuccessful] = useState(false);
  const [internalValue, setInternalValue] = useState(defaultValue || '');

  const previousValidateWithoutFocus = useRef(false);

  const inputValue = value === undefined ? internalValue : value;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value);
    let isValid;
    if (errorMessage || validationSuccessful || validateWithoutFocus) {
      isValid = validateInput(event.target.value);
    }
    if (onChange) {
      onChange(event, isValid);
    }
  };

  const validateInput = (value: string) => {
    if (validations?.length) {
      const firstFailingValidation = runValidations(validations, value);
      if (firstFailingValidation) {
        setErrorMessage(
          firstFailingValidation.errorMessage ??
            t(microcopySet.TEXT_INPUT, firstFailingValidation.errorMessageTranslationKey ?? ''),
        );
        return false;
      }
    }

    setErrorMessage(undefined);
    setValidationSuccessful(true);
    return true;
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const isValid = skipBlurValidation ? true : validateInput(event.target.value);
    setHasFocus(false);
    if (onBlur) {
      onBlur(event, isValid);
    }
  };

  const handleOnFocus = () => {
    setHasFocus(true);
  };

  useEffect(() => {
    setAnyErrors(!!errorMessage);
  }, [errorMessage, setAnyErrors]);

  const labelLifted = hasFocus || !!inputValue || liftedLabel;

  if (previousValidateWithoutFocus.current !== validateWithoutFocus) {
    previousValidateWithoutFocus.current = !!validateWithoutFocus;
    if (validateWithoutFocus) validateInput(internalValue);
  }

  return (
    <InputBase
      className={className}
      error={errorMessage || error}
      labelLifted={labelLifted}
      hasFocus={hasFocus}
      label={label}
      type={type}
      value={inputValue}
      showSuccess={validationSuccessful && showSuccess}
      successMessage={validationSuccessful ? successMessage : undefined}
      onChange={handleInputChange}
      onFocus={handleOnFocus}
      onBlur={handleBlur}
      multiline={multiline}
      disabled={disabled}
      style={style}
      maxLength={maxLength}
      name={name}
      required={required}
      onInvalid={(event) => validateInput(event.target.value)}
      autoComplete={autoComplete}
      autoFocus={!!autoFocus}
      placeholder={!label || labelLifted ? placeholder : undefined}
    />
  );
};

export default memo(TextInput);
