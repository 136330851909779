import { ContentfulClientApi, createClient } from 'contentful';
import config from './config';

const SPACE_ID = config.CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = config.CONTENTFUL_ACCESS_TOKEN;
const PREVIEW_TOKEN = config.CONTENTFUL_PREVIEW_TOKEN;
const ENV = config.CONTENTFUL_ENVIRONMENT;

const CLIENT_DEFAULT = {
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  environment: ENV,
};

let client: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>;

export function getClient() {
  if (!client) {
    client = createClient(CLIENT_DEFAULT).withoutUnresolvableLinks;
  }
  return client;
}

let previewClient: ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>;

export const getPreviewClient = () => {
  if (!previewClient) {
    previewClient = createClient({
      ...CLIENT_DEFAULT,
      accessToken: PREVIEW_TOKEN,
      host: 'preview.contentful.com',
    }).withoutUnresolvableLinks;
  }
  return previewClient;
};
